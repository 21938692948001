import { t } from 'i18next';
import { BRC_CERT, HYBRID, INTEGRIFOLIA } from 'utils/constants/product';
import { ORDER } from 'utils/constants/offerListings';
import { formatDate } from './FormatDate';
import { countryCodeToName, localizedSelectedObject } from './selectBoxUtils';

export const generateListingInfo = (productDetails) => {
  const {
    countryOfOrigin,
    destinationCountry,
    containerLoad,
    nutGrade,
    seasonHarvested,
    seasonProcessed,
    bestBeforeDate,
    minimumFoodCertificate,
    brcLevel,
    halaal,
    kosher,
    category,
  } = productDetails;

  const minimumFoodCertificateWording = minimumFoodCertificate === BRC_CERT && brcLevel
    ? `${minimumFoodCertificate} (${t(`shared.product.brc_levels.${brcLevel}`)})`
    : minimumFoodCertificate
  const nutStyle = localizedSelectedObject(
    `shared.product.nut_style.${productDetails.nutStyle}`,
    productDetails.nutStyle,
  );
  const region = localizedSelectedObject(`shared.product.regions.${productDetails.regionOfOrigin}`);
  const estimatedShippingDate =
    productDetails.estimatedShippingDate && formatDate(productDetails.estimatedShippingDate);

  const religion = [
    (halaal && t('shared.product.religion.halaal')),
    (kosher && t('shared.product.religion.kosher'))
  ].filter(e => e).join(', ')

  return [
    {
      key: 'region',
      description: region.label,
      className: null,
    },
    {
      key: 'country',
      description: countryCodeToName(countryOfOrigin),
      className: 'listing-card__container--title-uppercase',
    },
    ... category === ORDER ? [{
      key: 'destination_country',
      description: countryCodeToName(destinationCountry),
      className: 'listing-card__container--title-uppercase',
    }] : [],
    {
      key: 'container',
      description: containerLoad,
      className: 'listing-card__container--title-uppercase',
    },
    {
      key: 'nut_grade',
      description: nutGrade,
      className: null,
    },
    {
      key: 'nut_style',
      description: nutStyle.label,
      className: null,
    },
    {
      key: 'harvested',
      description: seasonHarvested,
      className: null,
    },
    {
      key: 'processed',
      description: seasonProcessed,
      className: null,
    },
    {
      key: 'best_before',
      description: formatDate(bestBeforeDate),
      className: null,
    },
    {
      key: 'esd',
      description: estimatedShippingDate,
      className: null,
    },
    {
      key: 'food_certificate',
      description: minimumFoodCertificateWording,
      className: 'listing-card__container--title-uppercase',
    },
    {
      key: 'religion',
      description: religion,
      className: 'text-capitalize',
    },
  ];
};

export const generateDNISListingInfo = (productDetails) => {
  const {
    countryOfOrigin,
    destinationCountry,
    containerLoad,
    nutGrade,
    size,
    soundKernelRecovery,
    unsoundKernelRecovery,
    treeVariant,
    treeCultivar,
    seasonHarvested,
    bestBeforeDate,
    category,
  } = productDetails;

  const region = localizedSelectedObject(`shared.product.regions.${productDetails.regionOfOrigin}`);
  const estimatedShippingDate =
    productDetails.estimatedShippingDate && formatDate(productDetails.estimatedShippingDate);

  const treeVariantWording = (treeVariant === HYBRID || treeVariant === INTEGRIFOLIA) && treeCultivar
  ? `${treeVariant} (${treeCultivar})`
  : treeVariant;

  return [
    {
      key: 'region',
      description: region.label,
      className: null,
    },
    {
      key: 'country',
      description: countryCodeToName(countryOfOrigin),
      className: 'listing-card__container--title-uppercase',
    },
    ... category === ORDER ? [{
      key: 'destination_country',
      description: countryCodeToName(destinationCountry),
      className: 'listing-card__container--title-uppercase',
    }] : [],
    {
      key: 'container',
      description: containerLoad,
      className: 'listing-card__container--title-uppercase',
    },
    {
      key: 'nut_grade',
      description: nutGrade,
      className: null,
    },
    {
      key: 'nut_size',
      description: size,
      className: null,
    },
    {
      key: 'sound_kernel_recovery',
      description: soundKernelRecovery,
      className: null,
    },
    {
      key: 'unsound_kernel_recovery',
      description: unsoundKernelRecovery,
      className: null,
    },
    {
      key: 'tree_variant',
      description: treeVariantWording,
      className: null,
    },
    {
      key: 'harvested',
      description: seasonHarvested,
      className: null,
    },
    {
      key: 'best_before',
      description: formatDate(bestBeforeDate),
      className: null,
    },
    {
      key: 'esd',
      description: estimatedShippingDate,
      className: null,
    },
  ];
};
