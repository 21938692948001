import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import DatePicker from 'components/BaseComponents/DatePicker';

const DateRangeFilter = ({ onStartDateChange, onEndDateChange, startDate, endDate, disabled }) => {
  const { t } = useTranslation();
  const [isStartDatePickerOpen, setIsStartDatePickerOpen] = useState(false);
  const [isEndDatePickerOpen, setIsEndDatePickerOpen] = useState(false);

  const handleStartDateChange = (selectedDate) => {
    if (selectedDate === '') {
      return onStartDateChange(null);
    }

    return onStartDateChange(selectedDate);
  };

  const handleEndDateChange = (selectedDate) => {
    if (selectedDate === '') {
      return onEndDateChange(null);
    }

    return onEndDateChange(selectedDate);
  };

  return (
    <div className="aggregate-data-filter__box--small">
      <p className="aggregate-data-filter__box--title-small margin-b-4px">
        {t('offer_listing.filter.date_range.start_date')}
      </p>
      <DatePicker
        id="startDateRange"
        focused={isStartDatePickerOpen}
        date={startDate}
        enablePastDates={true}
        onFocusChange={(isFocused) => setIsStartDatePickerOpen(isFocused)}
        displayFormat="LL"
        showClearDate={true}
        appendToBody={true}
        disabled={disabled}
        onChange={ handleStartDateChange }
      />
      <p className="aggregate-data-filter__box--title-small margin-b-4px">
        {t('offer_listing.filter.date_range.end_date')}
      </p>
      <DatePicker
        id="endDateRange"
        focused={isEndDatePickerOpen}
        date={endDate}
        enablePastDates={true}
        onFocusChange={(isFocused) => setIsEndDatePickerOpen(isFocused)}
        displayFormat="LL"
        showClearDate={true}
        appendToBody={true}
        disabled={disabled}
        onChange={handleEndDateChange}
      />
    </div>
  );
};

DateRangeFilter.defaultProps = {
  startDate: null,
  endDate: null,
  disabled: false,
  onStartDateChange: () => {},
  onEndDateChange: () => {},
};

DateRangeFilter.propTypes = {
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  disabled: PropTypes.bool,
  onStartDateChange: PropTypes.func,
  onEndDateChange: PropTypes.func,
};

export default DateRangeFilter;
