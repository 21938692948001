import {
  ADDITIONAL_DOCUMENT_TAB_SUBJECT,
  BANKING_DETAILS_TAB_SUBJECT,
  BUSINESS_CONTACTS_TAB_SUBJECT,
  BUSINESS_DETAILS_TAB_SUBJECT,
  COMPANY_MANAGEMENT_TAB_SUBJECT,
  FOOD_SAFETY_TAB_SUBJECT,
  PERSONAL_DETAILS_TAB_SUBJECT,
  USER_MANAGEMENT_SUBJECT,
  USER_PROFILE_SUBJECT
} from "ability";
import { AFFILIATE_PARTNER, BUYER, OWNER, SELLER } from "utils/constants/userRole";
import {
  hasAnyRole,
  hasNoRoles,
  hasPartnerRole,
  hasSellerRole,
  isCompanyOwner,
  isVerified,
} from "utils/helper/UserRoleValidation";

// ability to manage own profile
const defineProfileTabsAbility = ({ can, userDetails, companyDetails }) => {
  can('view', PERSONAL_DETAILS_TAB_SUBJECT);

  if (isCompanyOwner(userDetails, companyDetails)) {
    can('view', BUSINESS_DETAILS_TAB_SUBJECT);
    can('view', ADDITIONAL_DOCUMENT_TAB_SUBJECT);

    if (hasSellerRole(userDetails)) {
      can('view', FOOD_SAFETY_TAB_SUBJECT);
    }

    if (hasAnyRole(userDetails, [SELLER, BUYER, AFFILIATE_PARTNER])) {
      can('view', BUSINESS_CONTACTS_TAB_SUBJECT);
    }

    if (hasPartnerRole(userDetails)) {
      can('view', BANKING_DETAILS_TAB_SUBJECT);
    }

    if (isVerified(companyDetails)) {
      if(hasAnyRole(userDetails, [SELLER, BUYER]) || hasNoRoles(userDetails)) {
        can('invite', USER_MANAGEMENT_SUBJECT);
        can('view', COMPANY_MANAGEMENT_TAB_SUBJECT);
        can('view', USER_PROFILE_SUBJECT, { roles: { $nin: [OWNER] } });
      }
    }
  }

  return null;
};

export default defineProfileTabsAbility;
