import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import SelectBox from 'components/BaseComponents/SelectBox';

const SelectFilter = ({ className, title, placeholderText, options, selectedOption, disabled, onChange, isMulti }) => (
  <div className={classNames('aggregate-data-filter__box', className)}>
    <h4 className="aggregate-data-filter__box--title margin-b-16px">{title}</h4>
    <SelectBox
      width="full"
      placeholderText={placeholderText}
      options={options}
      isClearable={false}
      isSearchable={false}
      isDisabled={disabled}
      value={selectedOption}
      onChange={onChange}
      isMulti={isMulti}
    />
  </div>
);

SelectFilter.defaultProps = {
  className: '',
  title: null,
  placeholderText: 'Select...',
  options: [],
  selectedOption: null,
  disabled: false,
  isMulti: false,
  onChange: () => {},
};

const selectedOptionProptype = PropTypes.shape({
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
});

SelectFilter.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  placeholderText: PropTypes.string,
  isMulti: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  selectedOption: PropTypes.oneOfType([
    PropTypes.arrayOf(selectedOptionProptype),
    selectedOptionProptype,
  ]),
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
};

export default SelectFilter;
