import { apiSlice } from 'api';
import routes from 'utils/routes';
import {
  LISTINGS,
  PRODUCT_DETAILS,
  MY_LISTINGS,
  COMPANY_LISTINGS,
  FOLLOWING_LISTINGS,
  LEADING_BID_LISTINGS,
  OUTBID_LISTINGS,
} from 'utils/tagTypes';

const listingsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getListingDetails: builder.query({
      query: ({ listing_id }) => routes.listings.details({ listing_id }),
      transformResponse: (response) => response,
      providesTags: [LISTINGS],
    }),
    contactAdmin: builder.mutation({
      query: ({ listing_id, payload }) => ({
        url: routes.listings.contactAdmin({ listing_id }),
        method: 'POST',
        body: payload,
      }),
    }),
    publishListing: builder.mutation({
      query: ({ listing_id }) => ({
        url: routes.listings.publish({ listing_id }),
        method: 'PATCH',
      }),
      invalidatesTags: [LISTINGS, MY_LISTINGS],
    }),
    getMyListings: builder.query({
      query: (params) => routes.listings.getMyListings(params),
      providesTags: [MY_LISTINGS],
    }),
    getCompanyListings: builder.query({
      query: (params) => routes.listings.getCompanyListings(params),
      providesTags: [COMPANY_LISTINGS],
    }),
    unPublishListing: builder.mutation({
      query: (params) => ({
        url: routes.listings.unPublish(params),
        method: 'PATCH',
      }),
      invalidatesTags: [LISTINGS, MY_LISTINGS],
    }),
    placeBid: builder.mutation({
      query: ({ id, payload }) => ({
        url: routes.listings.placeBid({ id }),
        method: 'PATCH',
        body: payload,
      }),
      invalidatesTags: [LISTINGS, LEADING_BID_LISTINGS, OUTBID_LISTINGS],
    }),
    retractBid: builder.mutation({
      query: ({ id }) => ({
        url: routes.listings.retractBid({ id }),
        method: 'PATCH',
      }),
      invalidatesTags: [LISTINGS, LEADING_BID_LISTINGS, OUTBID_LISTINGS],
    }),
    rejectBid: builder.mutation({
      query: ({ id }) => ({
        url: routes.listings.rejectBid({ id }),
        method: 'DELETE',
      }),
      invalidatesTags: [LISTINGS, LEADING_BID_LISTINGS, OUTBID_LISTINGS],
    }),
    acceptOffer: builder.mutation({
      query: (params) => ({
        url: routes.listings.acceptOffer(params),
        method: 'PATCH',
      }),
    }),
    updateOfferPrice: builder.mutation({
      query: ({ id, payload }) => ({
        url: routes.listings.updateOfferPrice({ id }),
        method: 'PATCH',
        body: payload,
      }),
      invalidatesTags: [LISTINGS, PRODUCT_DETAILS],
    }),
    followListing: builder.mutation({
      query: ({ id }) => ({
        url: routes.listings.follow({ id }),
        method: 'PATCH',
      }),
      invalidatesTags: [FOLLOWING_LISTINGS],
    }),
    unfollowListing: builder.mutation({
      query: ({ id }) => ({
        url: routes.listings.unfollow({ id }),
        method: 'PATCH',
      }),
      invalidatesTags: [FOLLOWING_LISTINGS],
    }),
    getFollowingListings: builder.query({
      query: (params) => routes.listings.following(params),
      providesTags: [FOLLOWING_LISTINGS],
    }),
    getLeadingBidListings: builder.query({
      query: (params) => routes.listings.leading_bids(params),
      providesTags: [LEADING_BID_LISTINGS],
    }),
    getOutbidListings: builder.query({
      query: (params) => routes.listings.outbid(params),
      providesTags: [OUTBID_LISTINGS],
    }),
    deleteListing: builder.mutation({
      query: ({ id }) => ({
        url: routes.listings.delete({ id }),
        method: 'DELETE',
      }),
      invalidatesTags: [FOLLOWING_LISTINGS],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetListingDetailsQuery,
  useGetMyListingsQuery,
  useGetCompanyListingsQuery,
  useUnPublishListingMutation,
  usePlaceBidMutation,
  useRetractBidMutation,
  useRejectBidMutation,
  useAcceptOfferMutation,
  useUpdateOfferPriceMutation,
  useContactAdminMutation,
  usePublishListingMutation,
  useFollowListingMutation,
  useUnfollowListingMutation,
  useGetFollowingListingsQuery,
  useGetLeadingBidListingsQuery,
  useGetOutbidListingsQuery,
  useDeleteListingMutation,
} = listingsApi;
