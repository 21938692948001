import React, { useContext, useRef, useState } from 'react';
import { t } from 'i18next';
import Modal from 'components/BaseComponents/Modal';
import { useDeleteListingMutation } from 'api/listings';
import ListingDetailsContext from 'components/BiddingProcess/ListingDetails/ListingDetailsContext';
import useCustomNavigate from 'hooks/useCustomNavigate';
import CustomToastify from 'components/Shared/ToastNotification/CustomToastify';
import ToastNotification from 'components/Shared/ToastNotification';
import DeleteListingConfirmationModal from './DeleteListingConfirmationModal';
import './styles.scss';

const DeleteListingOption = () => {
  const toastRef = useRef();
  const { listingInformation, isOfferListing } = useContext(ListingDetailsContext);
  const { myOffers: navigateToMyOffers, myOrders: NavigateToMyOrders } = useCustomNavigate();
  const [deleteListing] = useDeleteListingMutation();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const onModalOpen = () => setIsModalVisible(true);
  const onModalClose = () => setIsModalVisible(false);

  const onDelete = () => {
    deleteListing({ id: listingInformation.id })
      .unwrap()
      .then(() => isOfferListing ? navigateToMyOffers() : NavigateToMyOrders())
      .catch(() => {
        toastRef?.current?.notify(
          <CustomToastify type="error" message={t('bidding_process.something_went_wrong')} />,
        );
      });
  }

  return (
    <>
      <button
        type="button"
        className="listing-details-breadcrumb__delete"
        onClick={onModalOpen}
      >
        <span>
          {t('bidding_process.delete_listing.button')}
        </span>
      </button>

      <Modal
        isVisible={isModalVisible}
        onOutsideClick={onModalClose}
        className="progress-card-modal"
      >
        <Modal.Content>
          <DeleteListingConfirmationModal onModalClose={onModalClose} onDeleteConfirm={onDelete} />
        </Modal.Content>
      </Modal>

      <ToastNotification toastRef={toastRef} />
    </>
  );
};

export default DeleteListingOption;
