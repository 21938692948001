import React, { Fragment, useReducer } from 'react';
import { t } from 'i18next';
import { useGetFollowingListingsQuery } from 'api/listings';
import { FOLLOWING_TAB, BIDDING_BASKET_PER_PAGE } from "utils/constants/biddingBasket";
import Pagination from 'components/BaseComponents/Pagination';
import SelectBox from 'components/BaseComponents/SelectBox';
import PortalsNavbar from 'components/NavBar/PortalsNavBar';
import { filterOptions } from 'utils/constants/offerListings';
import filterReducer, { HANDLE_PAGE_CHANGE, HANDLE_SORT_CHANGE, initialFilterState } from './filterReducer';
import CardsContainer from '../BiddingBasket/CardsContainer';
import './style.scss';

const FollowingListings = () => {
  const [filterState, dispatch] = useReducer(filterReducer, initialFilterState);
  const {
    sort: { label, column: sortColumn, direction: sortDirection },
    currentPage
  } = filterState;

  const selectedSort = { label, column: sortColumn, direction: sortDirection }

  const {
    data: followingListings = {},
    isLoading: isFollowingListingsLoading,
  } = useGetFollowingListingsQuery({
    currentPage,
    sortColumn,
    sortDirection,
    perPage: BIDDING_BASKET_PER_PAGE,
  });
  const { collection: followingListingsList = [] } = followingListings;

  const listingsCount = followingListings?.pagination?.totalCount || '0';
  const totalPages = Number(followingListings?.pagination?.totalPages);

  const title = t(`navbar.default.links.following`, { count: listingsCount });

  return (
    <Fragment>
      <PortalsNavbar />
      <div className="listings-wrapper">
        <div className="listings following-listings">
          <div className="following-listings__sort-filter">
            <h1>{title}</h1>

            <SelectBox
              className="following-listings__sort-filter--dropdown"
              options={filterOptions}
              isClearable={false}
              isSearchable={false}
              onChange={(value) => dispatch({ type: HANDLE_SORT_CHANGE, payload: value })}
              value={selectedSort}
            />
          </div>

          <CardsContainer
            listings={followingListingsList}
            isLoading={isFollowingListingsLoading}
            currentTab={FOLLOWING_TAB}
          />

          {totalPages > 1 && (
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPaginationClick={(value) => dispatch({ type: HANDLE_PAGE_CHANGE, payload: value })}
              firstText={t('admin_user_table.first_page')}
              lastText={t('admin_user_table.last_page')}
              nextText={t('admin_user_table.next_page')}
              previousText={t('admin_user_table.previous_page')}
              className="admin-portal__table--pagination"
            />
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default FollowingListings;
