import React, { useContext, useRef, useState } from 'react';
import { t } from 'i18next';
import classNames from 'classnames';
import Modal from 'components/BaseComponents/Modal';
import { useRejectBidMutation } from 'api/listings';
import ListingDetailsContext from 'components/BiddingProcess/ListingDetails/ListingDetailsContext';
import CustomToastify from 'components/Shared/ToastNotification/CustomToastify';
import ToastNotification from 'components/Shared/ToastNotification';
import RejectBidConfirmationModal from './RejectBidConfirmationModal';
import './styles.scss';

const RejectBidOption = () => {
  const toastRef = useRef();
  const { listingInformation } = useContext(ListingDetailsContext);
  const [rejectBid, { isLoading: isRejecting }] = useRejectBidMutation();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const onModalOpen = () => setIsModalVisible(true);
  const onModalClose = () => setIsModalVisible(false);

  const onReject = () => {
    rejectBid({ id: listingInformation.id })
      .unwrap()
      .then(() => {
        toastRef?.current?.notify(
          <CustomToastify type="success" message={t('bidding_process.reject_bid.success')} />,
        );
      })
      .catch(() => {
        toastRef?.current?.notify(
          <CustomToastify type="error" message={t('bidding_process.something_went_wrong')} />,
        );
      });
  }

  return (
    <>
      <button
        type="button"
        className={classNames('reject-bid__delete-button', {
          'reject-bid__delete-button--disabled': isRejecting,
        })}
        disabled={isRejecting}
        onClick={onModalOpen}
      >
        <span>
          {t('bidding_process.reject_bid.button')}
        </span>
      </button>

      <Modal
        isVisible={isModalVisible}
        onOutsideClick={onModalClose}
        className="progress-card-modal"
      >
        <Modal.Content>
          <RejectBidConfirmationModal onModalClose={onModalClose} onRejectConfirm={onReject} />
        </Modal.Content>
      </Modal>

      <ToastNotification toastRef={toastRef} />
    </>
  );
};

export default RejectBidOption;
