import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ReactFlagsSelect from 'react-flags-select';
import Tooltip from '../Tooltip';
import Icon from '../Icon';
import './styles.scss';

const CountrySelect = ({
  className,
  label,
  tooltip,
  country,
  placeholder,
  required,
  touched,
  error,
  disabled,
  onChange,
}) => (
  <div className={classNames('flag-select', className)}>
    {label && (
      <div className="flag-select__title flex items-center gap-4px">
        <span>{label}</span>
        {tooltip && (
          <Tooltip content={tooltip} showArrow={true} type="midnight-blue" size="tiny">
            <Icon name="help" color="default" />
          </Tooltip>
        )}
        {required && <span className="flag-select__title--required">*</span>}
      </div>
    )}
    <ReactFlagsSelect
      selectButtonClassName={classNames('flag-select__dropdown', {
        'flag-select__dropdown--error': touched && error,
      })}
      searchable
      disabled={disabled}
      placeholder={placeholder}
      selected={country}
      onSelect={onChange}
    />
  </div>
);

CountrySelect.defaultProps = {
  className: '',
  title: null,
  country: null,
  placeholder: null,
  required: false,
  touched: false,
  error: null,
  disabled: false,
  onChange: () => {},
};

CountrySelect.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  country: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  touched: PropTypes.bool,
  error: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
};

export default CountrySelect;
