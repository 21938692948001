import { useParams } from 'react-router-dom';
import useCustomNavigate from 'hooks/useCustomNavigate';
import { useGetCurrentUserQuery } from 'api/users';
import { useGetProductDetailsQuery } from 'api/products';
import { useGetListingDetailsQuery } from 'api/listings';
import { isCompanyOwner } from 'utils/helper/UserRoleValidation';
import { useGetCompanyQuery } from 'api/companies';
import { UNPUBLISHED } from 'utils/constants/offerListings';

const useListingMiddleWare = () => {
  const navigate = useCustomNavigate();
  const { productId } = useParams();
  const { data: user = {}, isFetching: isUserFetching } = useGetCurrentUserQuery();
  const { data: productDetails = {}, isFetching: isProductFetching } = useGetProductDetailsQuery(
    { product_id: productId },
    { skip: !productId },
  );

  const listingId = productDetails?.listingId;

  const { data: listingDetails = {}, isFetching: isListingDetailsFetching } =
    useGetListingDetailsQuery({ listing_id: listingId }, { skip: !listingId });

  const creatorCompanyId = listingDetails?.creator?.companyId;
  const { data: creatorCompany = {}, isFetching: isCompanyFetching } =
    useGetCompanyQuery({ id: creatorCompanyId }, { skip: !creatorCompanyId });

  const isListingAuthor = (user?.id === listingDetails?.creator?.id) || isCompanyOwner(user, creatorCompany);

  const isLoading = isUserFetching || isProductFetching || isListingDetailsFetching || isCompanyFetching;

  if (!isLoading) {
    const isListingUnpublished = listingDetails.status === UNPUBLISHED;

    if (!isListingUnpublished || !isListingAuthor) {
      navigate.listings();
    }
  }

  return isLoading;
};

export default useListingMiddleWare;
