import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Tooltip from 'components/BaseComponents/Tooltip';
import Icon from 'components/BaseComponents/Icon';
import CheckBox from 'components/BaseComponents/CheckBox';
import Button from 'components/BaseComponents/Button';
import { t } from 'i18next';

const CheckBoxFilter = ({
  className,
  title,
  isTooltipVisible,
  resetButtonType,
  tooltipText,
  disabled,
  options,
  dispatch,
}) => (
  <div className={classNames('aggregate-data-filter__box', className)}>
    <h4 className="aggregate-data-filter__box--title flex items-center gap-4px">
      <div className="aggregate-data-filter__box--title-block flex">
        <div>
          {title}
          {isTooltipVisible && (
            <Tooltip
              content={tooltipText}
              showArrow={true}
              type="midnight-blue"
              size="tiny"
              className="user__action--tooltip"
            >
              <Icon name="help" color="default" />
            </Tooltip>
          )}
        </div>
        <div>
          {resetButtonType && (
            <Button
              label={t('offer_listing.filter.reset_button')}
              className="aggregate-data-filter__box--reset-button"
              disabled={disabled}
              onClick={() => dispatch({ type: resetButtonType })}
            />
          )}
        </div>
      </div>
    </h4>
    <div className="margin-t-16px">
      {options &&
        options.map((option) => (
          <div className="margin-t-8px" key={option.name}>
            <CheckBox
              className="aggregate-data-filter__box--checkbox"
              label={option.label}
              isChecked={option.isChecked}
              onChange={option.onChange}
              disabled={disabled}
            />
          </div>
        ))}
    </div>
  </div>
);

CheckBoxFilter.defaultProps = {
  className: '',
  title: null,
  resetButtonType: null,
  dispatch: null,
  isTooltipVisible: false,
  tooltipText: null,
  disabled: false,
  options: null,
};

CheckBoxFilter.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  dispatch: PropTypes.func,
  resetButtonType: PropTypes.string,
  tooltipText: PropTypes.string,
  isTooltipVisible: PropTypes.bool,
  disabled: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      label: PropTypes.string,
      isChecked: PropTypes.bool,
      onChange: PropTypes.func,
    }),
  ),
};

export default CheckBoxFilter;
