import React from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import './styles.scss';
import IconButton from 'components/BaseComponents/IconButton';
import Icon from 'components/BaseComponents/Icon';

const RetractBidConfirmationModal = ({ onModalClose, onRetractConfirm }) => (
  <div className="retract-bid-modal">
    <div className="retract-bid-modal__header">
      <div className="retract-bid-modal__header--text">
        {t('bidding_process.retract_bid.confirm_modal.header')}
      </div>
      <IconButton icon={<Icon name="close" />} size="tiny" onClick={onModalClose} />
    </div>

    <div className="retract-bid-modal__body">
      <div className="flex justify-between">
        <div className="retract-bid-modal__body--text">{t('bidding_process.retract_bid.confirm_modal.text')}</div>
      </div>
    </div>

    <div className="retract-bid-modal__cta">
      <button
        className="retract-bid-modal__cta-back"
        type="button"
        onClick={onModalClose}
      >
        {t('bidding_process.back')}
      </button>
      <button
        className="retract-bid-modal__cta-confirm"
        type="button"
        onClick={() => {
          onModalClose();
          onRetractConfirm();
        }}
      >
        {t('bidding_process.confirm')}
      </button>
    </div>
  </div>
);

RetractBidConfirmationModal.propTypes = {
  onModalClose: PropTypes.func.isRequired,
  onRetractConfirm: PropTypes.func.isRequired,
};

export default RetractBidConfirmationModal;
