const ROOT_DOMAIN = 'https://macsm.org';
const MARKETING_PAGE_DOMAIN = 'https://marketing.macsm.org';

export const login = '/login';
export const signup = '/signup';
export const affiliatePartnerSignup = '/partner/signup';
export const listings = '/listings';
export const profile = '/profile';
export const listingDetails = '/listings/:id';
export const productOffer = '/create-listing/product-details/offer';
export const productOrder = '/create-listing/product-details/order';
export const contracts = '/contracts';
export const bids = '/bids';
export const contractDetails = '/contracts/:id';
export const updateProduct = '/create-listing/:productId/product-details';
export const reviewListing = '/create-listing/:productId/review-listing';
export const declaration = '/create-listing/:productId/declaration';
export const userDetails = '/users/:id';
export const offers = '/offers';
export const orders = '/orders';
export const userProfile = (userId) => `/users/${userId}`;

export const registrationSuccess = '/registration-success';
export const affiliatePartnerRegistrationSuccess = '/partner-registration-success';
export const forgotPassword = '/forgot-password';
export const verifyEmail = '/confirmation';
export const unauthorized = '/unauthorized';
export const notFound = '*';
export const invitedBuyerSetPassword = '/complete_sign_up';
export const joinCompanyRoute = '/join_company';
export const resetPassword = '/reset-password/:token';
export const resetPasswordSuccess = '/reset-password-success';
export const resetPasswordEmailCheck = '/reset-password-check-email';

export const landingPage = `${ROOT_DOMAIN}/`;
export const home = `${ROOT_DOMAIN}/home`;
export const aboutUs = `${ROOT_DOMAIN}/about`;
export const wholeSaleMarketPlace = `/wholesale-market`;
export const services = `${ROOT_DOMAIN}/services`;
export const support = `${ROOT_DOMAIN}/support`;
export const aggregateSalesStatistics = `${ROOT_DOMAIN}/aggregate-sales-statistics`;
export const contactUs = `${ROOT_DOMAIN}/contact`;
export const affiliatePartnerStaticPage = `${ROOT_DOMAIN}/affiliate-partner`;
export const cookies = `${ROOT_DOMAIN}/cookies`;
export const jurisdiction = `${ROOT_DOMAIN}/jurisdiction`;
export const privacy = `${ROOT_DOMAIN}/privacy`;
export const terms = `${ROOT_DOMAIN}/terms`;
export const retailsPage = `${MARKETING_PAGE_DOMAIN}/shop-macadamias/`;
export const marvelousMacs = `${MARKETING_PAGE_DOMAIN}/marvelous-macadamias/`;
export const newsPage = `${MARKETING_PAGE_DOMAIN}/news-with-macadamias/`;

export const seller = {
  dashboard: '/seller',
  foodSafety: '/create-listing/:productId/food-safety',
};

export const buyer = {
  dashboard: '/buyer',
};

export const company = {
  offers: 'company/offers',
  orders: 'company/orders',
  contracts: 'company/contracts',
  bids: '/company/bids',
};

export const admin = {
  dashboard: '/admin',
  allUser: '/admin/users',
  userDetails: '/admin/users/:id',
  userProfile: (userId) => `/admin/users/${userId}`,
  profile: '/admin/profile',
  productListings: '/admin/listing',
  affiliatePartners: '/admin/partners',
  affiliatePartnerDetails: '/admin/partners/:id',
  affiliatePartnerProfile: (userId) => `/admin/partners/${userId}`,
  contracts: '/admin/contracts',
  contractDetails: (id) => `/admin/contracts/${id}`,
  contractDetail: '/admin/contracts/:id',
  offerOderDetails: (id) => `/admin/listing/${id}`,
  adminOfferOderDetail: '/admin/listing/:id',
};

export const subscriptionMember = {
  dashboard: '/subscription_member',
  following: '/following',
};

export const affiliatePartner = {
  profile: '/partner/profile',
  portal: '/partner/portal',
  inviteBuyer: '/partner/invite-buyer',
  buyerInvitationSent: '/partner/buyer-invitation-sent',
  invitationSuccess: '/invitation-success',
};
