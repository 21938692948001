import { AFFILIATE_PARTNER, BUYER, SELLER } from "utils/constants/userRole";
import {
  hasAnyRole,
  hasNoRoles,
  hasPartnerRole,
  hasSellerRole,
  isCompanyOwner,
  isVerified,
  userRequestedVerification,
} from "utils/helper/UserRoleValidation";
import {
  BUSINESS_CONTACTS_TAB_SUBJECT,
  BUSINESS_DETAILS_TAB_SUBJECT,
  FOOD_SAFETY_TAB_SUBJECT,
  PERMISSIONS_TAB_SUBJECT,
  PERSONAL_DETAILS_TAB_SUBJECT,
  ADDITIONAL_DOCUMENT_TAB_SUBJECT,
  BANKING_DETAILS_TAB_SUBJECT,
  COMPANY_MANAGEMENT_TAB_SUBJECT,
  COMPANY_PPERMISSIONS_FORM_SUBJECT,
  USER_ROLES_FORM_SUBJECT,
  USER_PROFILE_SUBJECT,
  USER_ABILITY_SUBJECT,
} from 'ability';

// ability to manage user profile as admin
const defineProfileTabsAbility = ({ can, cannot, userDetails, companyDetails }) => {
  can('view', PERSONAL_DETAILS_TAB_SUBJECT);
  can('reject', USER_ABILITY_SUBJECT);
  can('view', BUSINESS_DETAILS_TAB_SUBJECT);
  can('view', ADDITIONAL_DOCUMENT_TAB_SUBJECT);

  if (userRequestedVerification(companyDetails)) {
    can('verify', USER_ABILITY_SUBJECT);
    can('requestDocument', USER_ABILITY_SUBJECT);
  }

  if (hasSellerRole(userDetails)) {
    can('view', FOOD_SAFETY_TAB_SUBJECT);
    can('view', COMPANY_PPERMISSIONS_FORM_SUBJECT);
  }

  if (hasAnyRole(userDetails, [SELLER, BUYER]) || hasNoRoles(userDetails)) {
    can('view', PERMISSIONS_TAB_SUBJECT);
    can('view', USER_ROLES_FORM_SUBJECT);
  }

  if (hasAnyRole(userDetails, [SELLER, BUYER, AFFILIATE_PARTNER])) {
    can('view', BUSINESS_CONTACTS_TAB_SUBJECT);
  }

  if (hasPartnerRole(userDetails)) {
    can('view', BANKING_DETAILS_TAB_SUBJECT);
  }

  if (isVerified(companyDetails)) {
    if (isCompanyOwner(userDetails, companyDetails)) {
      can('revoke', USER_ABILITY_SUBJECT);
      cannot('reject', USER_ABILITY_SUBJECT);
    }

    if (hasSellerRole(userDetails)) {
      can('importCSV', USER_ABILITY_SUBJECT);
    }

    if (hasPartnerRole(userDetails)) {
      can('swithcProfileView', USER_ABILITY_SUBJECT);
    }

    if (hasAnyRole(userDetails, [SELLER, BUYER]) || hasNoRoles(userDetails)) {
      can('view', COMPANY_MANAGEMENT_TAB_SUBJECT);
      can('view', USER_PROFILE_SUBJECT);
      can('viewAsAdmin', USER_PROFILE_SUBJECT);
    }
  }

  return null;
};

export default defineProfileTabsAbility;
