import React, { useContext, useRef, useState } from 'react';
import { t } from 'i18next';
import classNames from 'classnames';
import Modal from 'components/BaseComponents/Modal';
import { useRetractBidMutation } from 'api/listings';
import ListingDetailsContext from 'components/BiddingProcess/ListingDetails/ListingDetailsContext';
import CustomToastify from 'components/Shared/ToastNotification/CustomToastify';
import ToastNotification from 'components/Shared/ToastNotification';
import RetractBidConfirmationModal from './RetractBidConfirmationModal';
import './styles.scss';

const RetractBidOption = () => {
  const toastRef = useRef();
  const { listingInformation } = useContext(ListingDetailsContext);
  const [retractBid, { isLoading: isRetracting }] = useRetractBidMutation();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const onModalOpen = () => setIsModalVisible(true);
  const onModalClose = () => setIsModalVisible(false);

  const onRetract = () => {
    retractBid({ id: listingInformation.id })
      .unwrap()
      .then(() => {
        toastRef?.current?.notify(
          <CustomToastify type="success" message={t('bidding_process.retract_bid.success')} />,
        )
      })
      .catch(() => {
        toastRef?.current?.notify(
          <CustomToastify type="error" message={t('bidding_process.something_went_wrong')} />,
        )
      });
  };

  return (
    <>
      <button
        type="button"
        disabled={isRetracting}
        className={classNames('retract-bid__button', {
          'retract-bid__button--disabled': isRetracting,
        })}
        onClick={onModalOpen}
      >
        {t('bidding_process.retract_bid.button')}
      </button>

      <Modal
          isVisible={isModalVisible}
          onOutsideClick={onModalClose}
          className="progress-card-modal"
        >
        <Modal.Content>
          <RetractBidConfirmationModal onModalClose={onModalClose} onRetractConfirm={onRetract} />
        </Modal.Content>
      </Modal>

      <ToastNotification toastRef={toastRef} />
    </>
  );
};

export default RetractBidOption;
