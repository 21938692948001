import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import CountrySelect from 'components/BaseComponents/CountrySelect';
import Button from 'components/BaseComponents/Button';
import { t } from 'i18next';

const CountryFilter = ({ className, title, country, error, onChange, onReset, disabled, }) => (
  <div className={classNames('aggregate-data-filter__box', className)}>
    <h4 className="aggregate-data-filter__box--title flex items-center gap-4px">
      <div className="aggregate-data-filter__box--title-block">
        {title}
      </div>
      <div>
        {onReset && (
          <Button
            label={t('offer_listing.filter.reset_button')}
            className="aggregate-data-filter__box--reset-button"
            disabled={disabled}
            onClick={onReset}
          />
        )}
      </div>  
    </h4>
    <div className='margin-t-16px'>
      <CountrySelect country={country} error={error} disabled={disabled} onChange={onChange} />
    </div>
  </div>
);

CountryFilter.defaultProps = {
  className: '',
  title: null,
  country: null,
  error: null,
  disabled: false,
  onChange: () => {},
  onReset: null,
};

CountryFilter.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  country: PropTypes.string,
  error: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  onReset: PropTypes.func,
};

export default CountryFilter;
