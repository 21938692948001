import React from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import IconButton from 'components/BaseComponents/IconButton';
import Icon from 'components/BaseComponents/Icon';
import './styles.scss';

const DeleteListingConfirmationModal = ({ onModalClose, onDeleteConfirm }) => (
  <div className="delete-listing-modal-modal">
    <div className="place-counter-bid-modal__header">
      <div className="place-counter-bid-modal__header--text">
        {t('bidding_process.delete_listing.confirm_modal.header')}
      </div>
      <IconButton icon={<Icon name="close" />} size="tiny" onClick={onModalClose} />
    </div>

    <div className="delete-listing-modal__body">
      <div className="flex justify-between">
        <div className="delete-listing-modal__body--text">
          {t('bidding_process.delete_listing.confirm_modal.text')}
        </div>
      </div>
    </div>

    <div className="delete-listing-modal__cta">
      <button
        className="delete-listing-modal__cta-back"
        type="button"
        onClick={onModalClose}
      >
        {t('bidding_process.back')}
      </button>
      <button
        className="delete-listing-modal__cta-confirm"
        type="button"
        onClick={() => {
          onModalClose();
          onDeleteConfirm();
        }}
      >
        {t('bidding_process.confirm')}
      </button>
    </div>
  </div>
);

DeleteListingConfirmationModal.propTypes = {
  onModalClose: PropTypes.func.isRequired,
  onDeleteConfirm: PropTypes.func.isRequired,
};

export default DeleteListingConfirmationModal;
