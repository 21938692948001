import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Radio from 'components/BaseComponents/Radio';
import Tooltip from 'components/BaseComponents/Tooltip';
import Icon from 'components/BaseComponents/Icon';

const RadioFilter = ({
  className,
  title,
  isTooltipVisible,
  tooltipText,
  options,
  selectedOption,
  disabled,
  onChange,
}) => (
  <div className={classNames('aggregate-data-filter__box', className)}>
    <h4 className="aggregate-data-filter__box--title flex items-center gap-4px">
      {title}
      {isTooltipVisible && (
        <Tooltip
          content={tooltipText}
          showArrow={true}
          type="midnight-blue"
          size="tiny"
          className="user__action--tooltip"
        >
          <Icon name="help" color="default" />
        </Tooltip>
      )}
    </h4>
    <div className="aggregate-data-filter__box--radio-options flex flex-col gap-16px margin-t-16px">
      {options.map(({ label, name }) => (
        <Radio
          key={name}
          label={label}
          name={name}
          id={name}
          size="huge"
          disabled={disabled}
          isChecked={selectedOption === name}
          withCheckIcon={selectedOption === name}
          onChange={() => onChange(name)}
        />
      ))}
    </div>
  </div>
);

RadioFilter.defaultProps = {
  className: '',
  title: null,
  isTooltipVisible: false,
  tooltipText: null,
  options: [],
  selectedOption: null,
  disabled: false,
  onChange: () => {},
};

RadioFilter.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  isTooltipVisible: PropTypes.bool,
  tooltipText: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
    }),
  ),
  disabled: PropTypes.bool,
  selectedOption: PropTypes.string,
  onChange: PropTypes.func,
};

export default RadioFilter;
