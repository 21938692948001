const generateSignupBody = (params) => {
  const subscription_member_category = params.subscription_member_category?.value;
  const {
    user_first_name,
    user_last_name,
    name,
    registration_number,
    country_code_of_registration,
    user_email,
    user_password,
  } = params;

  return {
    name,
    registration_number,
    country_code_of_registration,
    subscription_member_category,
    users_attributes: [{
      email: user_email,
      password: user_password,
      first_name: user_first_name,
      last_name: user_last_name,
    }],
  };
};

const generateInviteCompanyMemberBody = (params) => {
  const {
    email,
    first_name,
    last_name,
  } = params;

  return {
    user_attributes: {
      email,
      first_name,
      last_name,
    },
  };
};

const generateLoginBody = (params) => {
  const { email, password, remember_me } = params;

  return {
    email,
    password,
    remember_me,
  };
};

const generateBusinessDetailsBody = (params) => {
  const {
    name,
    country_code_of_registration,
    registration_number,
    custom_code,
    domicile_address_attributes_line_1,
    domicile_address_attributes_line_2,
    domicile_address_attributes_city,
    domicile_address_attributes_zip_code,
    domicile_address_attributes_country_code,
    email,
    address_attributes_line_1,
    address_attributes_line_2,
    address_attributes_city,
    address_attributes_zip_code,
    address_attributes_country_code,
    accept_terms_and_conditions,
    registration_reason,
    preferred_contact_method,
    registration_certificate,
    incorporation_certificate,
    company_customs_certificate,
  } = params;

  const businessDetailsFormData = new FormData();
  if (name){
    businessDetailsFormData.append('company_attributes[name]', name);
  }
  if (country_code_of_registration){
    businessDetailsFormData.append(
      'company_attributes[country_code_of_registration]',
      country_code_of_registration,
    );
  }
  if (registration_number) {
    businessDetailsFormData.append('company_attributes[registration_number]', registration_number);
  }
  if (custom_code) {
    businessDetailsFormData.append('company_attributes[custom_code]', custom_code || '');
  }
  if (domicile_address_attributes_line_1) {
    businessDetailsFormData.append(
      'company_attributes[domicile_address_attributes][line_1]',
      domicile_address_attributes_line_1,
    );
  }

  if (domicile_address_attributes_line_2) {
    businessDetailsFormData.append(
      'company_attributes[domicile_address_attributes][line_2]',
      domicile_address_attributes_line_2,
    );
  }

  if (domicile_address_attributes_city) {
    businessDetailsFormData.append(
      'company_attributes[domicile_address_attributes][city]',
      domicile_address_attributes_city,
    );
  }
  if (domicile_address_attributes_zip_code) {
    businessDetailsFormData.append(
      'company_attributes[domicile_address_attributes][zip_code]',
      domicile_address_attributes_zip_code,
    );
  }
  if (domicile_address_attributes_country_code) {
    businessDetailsFormData.append(
      'company_attributes[domicile_address_attributes][country_code]',
      domicile_address_attributes_country_code,
    );
  }
  if (email) {
    businessDetailsFormData.append('company_attributes[email]', email);
  }
  if (address_attributes_line_1) {
    businessDetailsFormData.append(
      'company_attributes[address_attributes][line_1]',
      address_attributes_line_1,
    );
  }

  if (address_attributes_line_2) {
    businessDetailsFormData.append(
      'company_attributes[address_attributes][line_2]',
      address_attributes_line_2,
    );
  }

  if (address_attributes_city) {
    businessDetailsFormData.append(
      'company_attributes[address_attributes][city]',
      address_attributes_city,
    );
  }
  if (address_attributes_zip_code) {
    businessDetailsFormData.append(
      'company_attributes[address_attributes][zip_code]',
      address_attributes_zip_code,
    );
  }
  if (address_attributes_zip_code) {
    businessDetailsFormData.append(
      'company_attributes[address_attributes][zip_code]',
      address_attributes_zip_code,
    );
  }
  if (address_attributes_country_code) {
    businessDetailsFormData.append(
      'company_attributes[address_attributes][country_code]',
      address_attributes_country_code,
    );
  }
  if (preferred_contact_method) {
    businessDetailsFormData.append(
      'company_attributes[preferred_contact_method]',
      preferred_contact_method,
    );
  }
  if (accept_terms_and_conditions) {
    businessDetailsFormData.append(
      'company_attributes[accept_terms_and_conditions]',
      accept_terms_and_conditions,
    );
  }
  if (registration_reason) {
    businessDetailsFormData.append(
      'company_attributes[registration_reason]',
      registration_reason,
    );
  }

  if (registration_certificate instanceof File) {
    businessDetailsFormData.append(
      'company_attributes[registration_certificate]',
      registration_certificate,
    );
  }
  if (incorporation_certificate instanceof File) {
    businessDetailsFormData.append(
      'company_attributes[incorporation_certificate]',
      incorporation_certificate,
    );
  }
  if (company_customs_certificate instanceof File) {
    businessDetailsFormData.append(
      'company_attributes[company_customs_certificate]',
      company_customs_certificate,
    );
  }

  return businessDetailsFormData;
};

const generateBusinessContactBody = (params, removedDirectors) => {
  const { authorized_person, directors, trade_references } = params;

  const businessContactsFormData = new FormData();
  removedDirectors.forEach((removedDirector) => {
    const { id, first_name, last_name, identification_number } = removedDirector;
    businessContactsFormData.append('directors_attributes[][id]', id);
    businessContactsFormData.append('directors_attributes[][first_name]', first_name);
    businessContactsFormData.append('directors_attributes[][last_name]', last_name);
    businessContactsFormData.append(
      'directors_attributes[][identification_number]',
      identification_number,
    );
    businessContactsFormData.append('directors_attributes[][_destroy]', true);
  });

  directors.forEach((director) => {
    const { id = null, first_name, last_name, identification_number, id_document } = director;

    if (id) {
      businessContactsFormData.append('directors_attributes[][id]', id);
    }

    businessContactsFormData.append('directors_attributes[][first_name]', first_name);
    businessContactsFormData.append('directors_attributes[][last_name]', last_name);
    businessContactsFormData.append(
      'directors_attributes[][identification_number]',
      identification_number,
    );

    if (id_document instanceof File) {
      businessContactsFormData.append('directors_attributes[][id_document]', id_document);
    }
  });

  const {
    id: idPerson,
    first_name,
    last_name,
    identification_number,
    company_resolution,
    id_document,
    signed_letter,
  } = authorized_person;

  if (idPerson) {
    businessContactsFormData.append('authorized_person_attributes[id]', idPerson);
  }

  businessContactsFormData.append('authorized_person_attributes[first_name]', first_name);
  businessContactsFormData.append('authorized_person_attributes[last_name]', last_name);
  businessContactsFormData.append(
    'authorized_person_attributes[identification_number]',
    identification_number,
  );

  if (company_resolution instanceof File) {
    businessContactsFormData.append(
      'authorized_person_attributes[company_resolution]',
      company_resolution,
    );
  }

  if (id_document instanceof File) {
    businessContactsFormData.append('authorized_person_attributes[id_document]', id_document);
  }

  if (signed_letter instanceof File) {
    businessContactsFormData.append('authorized_person_attributes[signed_letter]', signed_letter);
  }

  trade_references.forEach((tradeReference) => {
    const { id, reference, contact_person_name, phone_number, email } = tradeReference;

    if (id) {
      businessContactsFormData.append('trade_references_attributes[][id]', id);
    }

    businessContactsFormData.append('trade_references_attributes[][reference]', reference);
    businessContactsFormData.append(
      'trade_references_attributes[][contact_person_name]',
      contact_person_name,
    );
    businessContactsFormData.append('trade_references_attributes[][phone_number]', phone_number);
    businessContactsFormData.append('trade_references_attributes[][email]', email);
  });

  return businessContactsFormData;
};

const generateFoodSafetyBody = (params) => {
  const {
    id,
    safety_document,
    safety_process_diagram,
    safety_data_sheet,
    total_diet_study,
    regulatory_approvals,
    ppecb_company_certificate,
    global_gap_company_certificate,
    esg_company_certificate,
    organic_certificate,
  } = params;
  const foodsafetyForm = new FormData();

  if (id) {
    foodsafetyForm.append('food_safety_document_attributes[id]', id);
  }
  if (safety_document instanceof File) {
    foodsafetyForm.append('food_safety_document_attributes[safety_document]', safety_document);
  }
  if (safety_process_diagram instanceof File) {
    foodsafetyForm.append(
      'food_safety_document_attributes[safety_process_diagram]',
      safety_process_diagram,
    );
  }
  if (safety_data_sheet instanceof File) {
    foodsafetyForm.append('food_safety_document_attributes[safety_data_sheet]', safety_data_sheet);
  }
  if (total_diet_study instanceof File) {
    foodsafetyForm.append('food_safety_document_attributes[total_diet_study]', total_diet_study);
  }
  if (regulatory_approvals instanceof File) {
    foodsafetyForm.append(
      'food_safety_document_attributes[regulatory_approvals]',
      regulatory_approvals,
    );
  }
  if (ppecb_company_certificate instanceof File) {
    foodsafetyForm.append(
      'food_safety_document_attributes[ppecb_company_certificate]',
      ppecb_company_certificate,
    );
  }
  if (global_gap_company_certificate instanceof File) {
    foodsafetyForm.append(
      'food_safety_document_attributes[global_gap_company_certificate]',
      global_gap_company_certificate,
    );
  }
  if (esg_company_certificate instanceof File) {
    foodsafetyForm.append(
      'food_safety_document_attributes[esg_company_certificate]',
      esg_company_certificate,
    );
  }
  if (organic_certificate instanceof File) {
    foodsafetyForm.append(
      'food_safety_document_attributes[organic_certificate]',
      organic_certificate,
    );
  }

  return foodsafetyForm;
};

const generateAdditionalDocumentBody = (params, removedAdditionalDocumentIds) => {
  const additionalDocumentForm = new FormData();

  params?.additional_documents?.forEach((document) => {
    if (document instanceof File) {
      additionalDocumentForm.append('additional_documents[]', document);
    }
  });

  additionalDocumentForm.append(
    'marked_for_destruction_additional_document_ids[]',
    removedAdditionalDocumentIds,
  );

  return additionalDocumentForm;
};

const generateReligionCertificatesFormBody = (params) => {
  const { halaal_certificate, kosher_certificate } = params;

  const religionCertificatesForm = new FormData();

  if (halaal_certificate instanceof File) {
    religionCertificatesForm.append('halaal_certificate', halaal_certificate);
  }
  if (kosher_certificate instanceof File) {
    religionCertificatesForm.append('kosher_certificate', kosher_certificate);
  }

  return religionCertificatesForm.has('halaal_certificate') ||
    religionCertificatesForm.has('kosher_certificate')
    ? religionCertificatesForm
    : {};
};

const generateSignedDocumentBody = (params) => {
  const { signed_contract } = params;

  const signedDocumentForm = new FormData();

  if (signed_contract instanceof File) {
    signedDocumentForm.append('signed_contract', signed_contract);
  }

  return signedDocumentForm;
};

const generateContractDocumentsForm = (params) => {
  const contractDocumentsForm = new FormData();

  Array.from(params).forEach((document) => {
    if (document instanceof File) {
      contractDocumentsForm.append('documents', document);
    }
  });
  return contractDocumentsForm;
};

const generateOfferFoodSafetyForm = (params) => {
  const createOfferFoodSafetyForm = new FormData();

  createOfferFoodSafetyForm.append('want_access_to_pasteurization', params.access_to_pasteurization);
  createOfferFoodSafetyForm.append('pasteurized', params.consignment_value);
  createOfferFoodSafetyForm.append('organic', params.organic);
  createOfferFoodSafetyForm.append('batch', Number(params.num_of_batch));

  // microbiological tests
  createOfferFoodSafetyForm.append('e_coli_attributes[method]', params.e_coli_test_method.value);
  createOfferFoodSafetyForm.append('e_coli_attributes[value]', params.e_coli_test_value);
  createOfferFoodSafetyForm.append(
    'salmonella_attributes[method]',
    params.salmonella_test_method.value,
  );
  createOfferFoodSafetyForm.append('salmonella_attributes[value]', params.salmonella_test_value);
  createOfferFoodSafetyForm.append(
    'plate_count_attributes[method]',
    params.plate_count_test_method.value,
  );
  createOfferFoodSafetyForm.append('plate_count_attributes[value]', params.plate_count_test_value);
  createOfferFoodSafetyForm.append('yeast_attributes[method]', params.yeast_test_method.value);
  createOfferFoodSafetyForm.append('yeast_attributes[value]', params.yeast_test_value);
  createOfferFoodSafetyForm.append('mould_attributes[method]', params.mould_test_method.value);
  createOfferFoodSafetyForm.append('mould_attributes[value]', params.mould_test_value);
  createOfferFoodSafetyForm.append(
    'coliforms_0_4_attributes[method]',
    params.coliforms_0_4_test_method.value,
  );
  createOfferFoodSafetyForm.append(
    'coliforms_0_4_attributes[value]',
    params.coliforms_0_4_test_value,
  );
  createOfferFoodSafetyForm.append(
    'coliforms_5_8_attributes[method]',
    params.coliforms_5_8_test_method.value,
  );
  createOfferFoodSafetyForm.append(
    'coliforms_5_8_attributes[value]',
    params.coliforms_5_8_test_value,
  );
  createOfferFoodSafetyForm.append(
    'lysteria_monocytogenes_attributes[method]',
    params.lysteria_monocytogenes_test_method.value,
  );
  createOfferFoodSafetyForm.append(
    'lysteria_monocytogenes_attributes[value]',
    params.lysteria_monocytogenes_test_value,
  );
  createOfferFoodSafetyForm.append(
    'staphylococcus_aureus_attributes[method]',
    params.staphylococcus_aureus_test_method.value,
  );
  createOfferFoodSafetyForm.append(
    'staphylococcus_aureus_attributes[value]',
    params.staphylococcus_aureus_test_value,
  );
  createOfferFoodSafetyForm.append(
    'enterobacteria_attributes[method]',
    params.enterobacteria_test_method.value,
  );
  createOfferFoodSafetyForm.append(
    'enterobacteria_attributes[value]',
    params.enterobacteria_test_value,
  );
  createOfferFoodSafetyForm.append(
    'b_cereus_attributes[method]',
    params.b_cereus_test_method.value,
  );
  createOfferFoodSafetyForm.append('b_cereus_attributes[value]', params.b_cereus_test_value);

  // chemical tests
  createOfferFoodSafetyForm.append(
    'total_aflatoxin_attributes[method]',
    params.total_aflatoxin_test_method.value,
  );
  createOfferFoodSafetyForm.append(
    'total_aflatoxin_attributes[value]',
    params.total_aflatoxin_test_value,
  );
  createOfferFoodSafetyForm.append(
    'aflatoxin_b1_attributes[method]',
    params.aflatoxin_b1_test_method.value,
  );
  createOfferFoodSafetyForm.append(
    'aflatoxin_b1_attributes[value]',
    params.aflatoxin_b1_test_value,
  );
  createOfferFoodSafetyForm.append(
    'free_fatty_acid_attributes[method]',
    params.free_fatty_acid_test_method.value,
  );
  createOfferFoodSafetyForm.append(
    'free_fatty_acid_attributes[value]',
    params.free_fatty_acid_test_value,
  );
  createOfferFoodSafetyForm.append(
    'peroxide_value_2_year_attributes[method]',
    params.peroxide_value2_test_method.value,
  );
  createOfferFoodSafetyForm.append(
    'peroxide_value_2_year_attributes[value]',
    params.peroxide_value2_test_value,
  );
  createOfferFoodSafetyForm.append(
    'peroxide_value_1_year_attributes[method]',
    params.peroxide_value1_test_method.value,
  );
  createOfferFoodSafetyForm.append(
    'peroxide_value_1_year_attributes[value]',
    params.peroxide_value1_test_value,
  );

  // The following code is related to CCL, which is currently turned off due to some business policy
  // but might be enabled in future

  // if (params.total_aflatoxin_ccl_checkbox) {
  //   createOfferFoodSafetyForm.append(
  //     'total_aflatoxin_attributes[ccl]',
  //     params.total_aflatoxin_ccl_checkbox,
  //   );
  // }
  // if (params.total_aflatoxin_ccl_value) {
  //   createOfferFoodSafetyForm.append(
  //     'total_aflatoxin_attributes[max_limit]',
  //     params.total_aflatoxin_ccl_value,
  //   );
  // }

  // if (params.aflatoxin_b1_ccl_checkbox) {
  //   createOfferFoodSafetyForm.append(
  //     'aflatoxin_b1_attributes[ccl]',
  //     params.aflatoxin_b1_ccl_checkbox,
  //   );
  // }
  // if (params.aflatoxin_b1_ccl_value) {
  //   createOfferFoodSafetyForm.append(
  //     'aflatoxin_b1_attributes[max_limit]',
  //     params.aflatoxin_b1_ccl_value,
  //   );
  // }

  // heavy metal tests
  if (params.lead_test_method?.value && params.lead_test_value) {
    createOfferFoodSafetyForm.append('lead_attributes[method]', params.lead_test_method.value);
    createOfferFoodSafetyForm.append('lead_attributes[value]', params.lead_test_value);
  }
  if (params.mercury_test_method?.value && params.mercury_test_value) {
    createOfferFoodSafetyForm.append(
      'mercury_attributes[method]',
      params.mercury_test_method.value,
    );

    createOfferFoodSafetyForm.append('mercury_attributes[value]', params.mercury_test_value);
  }
  if (params.cadmium_test_method?.value && params.cadmium_test_value) {
    createOfferFoodSafetyForm.append(
      'cadmium_attributes[method]',
      params.cadmium_test_method.value,
    );

    createOfferFoodSafetyForm.append('cadmium_attributes[value]', params.cadmium_test_value);
  }
  if (params.arsenic_test_method?.value && params.arsenic_test_value) {
    createOfferFoodSafetyForm.append(
      'arsenic_attributes[method]',
      params.arsenic_test_method.value,
    );

    createOfferFoodSafetyForm.append('arsenic_attributes[value]', params.arsenic_test_value);
  }

  // certificates
  params.microbiological_certificate?.forEach((certificate) => {
    if (certificate.fileName instanceof File) {
      createOfferFoodSafetyForm.append('microbiological_test_certificates[]', certificate.fileName);
    }
  });

  params.chemical_certificate?.forEach((certificate) => {
    if (certificate.fileName instanceof File) {
      createOfferFoodSafetyForm.append('chemical_test_certificates[]', certificate.fileName);
    }
  });

  params.heavy_metal_certificate?.forEach((certificate) => {
    if (certificate.fileName instanceof File) {
      createOfferFoodSafetyForm.append('metal_level_test_certificates[]', certificate.fileName);
    }
  });

  params.max_residue_certificate?.forEach((certificate) => {
    if (certificate.fileName instanceof File) {
      createOfferFoodSafetyForm.append(
        'maximum_residue_level_certificates[]',
        certificate.fileName,
      );
    }
  });

  params.listing_organic_certificates?.forEach((certificate) => {
    if (certificate.fileName instanceof File) {
      createOfferFoodSafetyForm.append('listing_organic_certificates[]', certificate.fileName);
    }
  });

  createOfferFoodSafetyForm.append('compliance_institute', params.compliance_institute);

  return createOfferFoodSafetyForm;
};

const generateDnisOfferFoodSafetyForm = (params) => {
  const createOfferFoodSafetyForm = new FormData();

  createOfferFoodSafetyForm.append('organic', params.organic);

  // certificates
  params.ppecb_certificate?.forEach((certificate) => {
    if (certificate.fileName instanceof File) {
      createOfferFoodSafetyForm.append('ppecb_certificates[]', certificate.fileName);
    }
  });

  params.global_gap_certificate?.forEach((certificate) => {
    if (certificate.fileName instanceof File) {
      createOfferFoodSafetyForm.append('global_gap_certificates[]', certificate.fileName);
    }
  });

  params.listing_organic_certificates?.forEach((certificate) => {
    if (certificate.fileName instanceof File) {
      createOfferFoodSafetyForm.append('listing_organic_certificates[]', certificate.fileName);
    }
  });

  if (
    !createOfferFoodSafetyForm.has('ppecb_certificates[]') &&
    !createOfferFoodSafetyForm.has('global_gap_certificates[]')
  ) {
    createOfferFoodSafetyForm.append('ppecb_certificates[]', 'null');
  }

  return createOfferFoodSafetyForm;
};

const generateKernelProductBody = (params, category) => {
  const listing_attributes = category ? { listing_attributes: { category } } : {}
  const kernelProductProperties = [
    'nut_grade',
    'nut_style',
    'season_harvested',
    'season_processed',
    'minimum_food_certificate',
    'region_of_origin',
    'container_load',
    'payment_options',
    'brc_level',
  ];
  const selectBoxValues = {};

  kernelProductProperties.forEach((key) => {
    selectBoxValues[key] = params[key]?.value;
  });

  return {
    ...params,
    ...selectBoxValues,
    ...listing_attributes,
  };
};

const generateDriedNutInShellProductBody = (params, category) => {
  const listing_attributes = category ? { listing_attributes: { category } } : {}

  const driedNutInShellproductProperties = [
    'nut_grade',
    'season_harvested',
    'region_of_origin',
    'container_load',
    'payment_options',
    'size',
    'unsound_kernel_recovery',
    'sound_kernel_recovery',
    'tree_variant',
    'tree_cultivar',
  ];

  const selectBoxValues = {};

  driedNutInShellproductProperties.forEach((key) => {
    selectBoxValues[key] = params[key]?.value;
  });


  return {
    ...params,
    ...selectBoxValues,
    ...listing_attributes,
  };
};

const generateEditDriedNutInShellProductBody = (params) => {
  const driedNutInShellproductProperties = [
    'nut_grade',
    'season_harvested',
    'region_of_origin',
    'container_load',
    'payment_options',
    'size',
    'unsound_kernel_recovery',
    'sound_kernel_recovery',
    'tree_variant',
    'tree_cultivar',
    'moisture_content',
  ];

  const selectBoxValues = {};

  driedNutInShellproductProperties.forEach((key) => {
    selectBoxValues[key] = params[key]?.value;
  });

  return {
    ...params,
    ...selectBoxValues,
  };
};

const generateInviteBuyerBody = (params) => {
  const {
    first_name,
    last_name,
    company_registration_number,
    country_code_of_registration,
    email,
    company_name,
  } = params;

  return {
    first_name,
    last_name,
    email,
    company_attributes: {
      name: company_name,
      registration_number: company_registration_number,
      country_code_of_registration,
    },
  };
};

const generateSetPasswordBody = (params, token) => {
  const { password, password_confirmation } = params;

  return {
    verify_token: token,
    password,
    confirmed_password: password_confirmation,
  };
};

const generateCompanyPermissionsBody = (params) => {
  const { trade_kernel, trade_dried_nut_in_shell } = params;
  const companyPermissionsForm = new FormData();

  companyPermissionsForm.append(
    'company_permissions_attributes[trade_kernel]',
    trade_kernel || false,
  );

  companyPermissionsForm.append(
    'company_permissions_attributes[trade_dried_nut_in_shell]',
    trade_dried_nut_in_shell || false,
  );

  return companyPermissionsForm;
};

const generateUpdateRolesBody = (params) => {
  const { buyer, seller, owner } = params;
  const rolesForm = new FormData();

  rolesForm.append(buyer ? 'roles_to_add[]' : 'roles_to_remove[]', 'buyer',);
  rolesForm.append(seller ? 'roles_to_add[]' : 'roles_to_remove[]', 'seller',);
  rolesForm.append(owner ? 'roles_to_add[]' : 'roles_to_remove[]', 'owner',);

  return rolesForm;
};

export {
  generateSignupBody,
  generateBusinessDetailsBody,
  generateBusinessContactBody,
  generateLoginBody,
  generateFoodSafetyBody,
  generateAdditionalDocumentBody,
  generateReligionCertificatesFormBody,
  generateSignedDocumentBody,
  generateOfferFoodSafetyForm,
  generateDnisOfferFoodSafetyForm,
  generateKernelProductBody,
  generateDriedNutInShellProductBody,
  generateEditDriedNutInShellProductBody,
  generateInviteBuyerBody,
  generateSetPasswordBody,
  generateContractDocumentsForm,
  generateCompanyPermissionsBody,
  generateUpdateRolesBody,
  generateInviteCompanyMemberBody,
};
